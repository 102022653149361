import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const underlinePrimary = defineStyle({
  textDecoration: 'underline',
  textDecorationColor: 'pastelBlue.600',
  textDecorationThickness: '1.3px',
  _hover: {
    textDecorationColor: 'pastelBlue.600',
    textDecorationThickness: '1.3px',
    color: 'pastelBlue.600',
  },
})

const boldPrimary = defineStyle({
  textDecoration: 'none',
  fontWeight: '500',
  _hover: {
    textDecoration: 'none',
  },
})

const nav = defineStyle({
  borderRadius: 'md',
  padding: '0.1rem 0.5rem',
  _hover: {
    _light: {
      backgroundColor: 'gray.100',
    },
    _dark: {
      backgroundColor: 'gray.700',
    },
    textDecoration: 'none',
    transition: 'all 0.1s ease-in-out',
  },
})

const siteTitle = defineStyle({
  _hover: {
    textDecoration: 'none',
  },
})

const icon = defineStyle({
  color: 'gray.300',
  fontSize: '1.15em',
  _hover: {
    // color: 'pastelBlue.600',
    _dark: {
      color: 'gray.100',
    },
    _light: {
      color: 'gray.500',
    },
    transition: 'all 0.1s ease-in-out',
  },
})

const noUnderline = defineStyle({
  _hover: {
    textDecoration: 'none',
  },
})

export const linkTheme = defineStyleConfig({
  variants: {
    boldPrimary: boldPrimary,
    underlinePrimary: underlinePrimary,
    nav: nav,
    icon: icon,
    siteTitle: siteTitle,
    noUnderline: noUnderline,
  },
})
