import { Heading, Stack } from '@chakra-ui/react'
import { ProjectLink } from '../components'
import researchData from '../info/research.json'

const Research = () => {
  return (
    <section id="Research">
      <Heading size="sm" variant="primary">
        Research
      </Heading>
      <Stack>
        {researchData.map((research, idx) => (
          <ProjectLink
            size="md"
            text={research.title}
            smallText={research.professor}
            link={research.link}
            key={idx}
          />
        ))}
      </Stack>
    </section>
  )
}

export default Research
