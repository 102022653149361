import { extendTheme } from '@chakra-ui/react'

import { headingTheme } from './components/Heading'
import { linkTheme } from './components/Link'
import { buttonTheme } from './components/Button'

const config = {
  initialColorMode: 'system',
}

const colors = {
  pastelBlue: {
    900: '#5B80A3',
    800: '#2a3f52',
    700: '#5A85AC',
    600: '#6A9CCA',
    500: '#9BBFE3',
    400: '#B9D4EE',
    300: '#B9D4EE',
    200: '#D7E8F9',
    100: '#f2f6fa',
  },
  pastelGreen: {
    50: '#f2f9f7',
    100: '#c9e6e0',
    200: '#9ad0c5',
    300: '#5fb5a3',
    400: '#3ca58e',
    500: '#0a8d72',
    600: '#00785e',
    700: '#00604c',
    800: '#005240',
    900: '#003b2f',
  },
}

export const theme = extendTheme({
  config: config,
  colors: colors,
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  body: {
    bg: 'white',
    color: 'gray.700',
    fontWeight: '300',
  },
  components: {
    Heading: headingTheme,
    Link: linkTheme,
    Button: buttonTheme,
  },
})
