import { Heading, Link, Flex } from '@chakra-ui/react'
import Contact from './contact'

const Navigation = () => {
  const navItems = ['About', 'Projects', 'Experience', 'Research']

  return (
    <div className="nav">
      <Heading size="md" variant="siteTitle">
        <Link href="/" variant="siteTitle">
          Zora Zhang
        </Link>
      </Heading>
      <Flex flexDirection="column" gap="0.4rem">
        {navItems.map((ele, idx) => (
          <dt className="navItems" key={idx}>
            <Link variant="nav" href={`#${ele}`}>
              {ele}
            </Link>
          </dt>
        ))}
        <dt className="navItems">
          <Link variant="nav" href={'https://notes.zorazrr.com/'}>
            Digital Garden
          </Link>
        </dt>
      </Flex>
      <Contact />
    </div>
  )
}

export default Navigation
