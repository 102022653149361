import { Text, Flex, Divider } from '@chakra-ui/react'

const TextWithDate = ({ text, time, ...props }) => {
  return (
    <Flex flexDirection="row" width={'100%'} {...props}>
      <Text fontSize="xs" style={{ fontWeight: '300' }}>
        {text}
      </Text>
      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="center"
        paddingLeft={2}
        paddingRight={2}
      >
        <Divider />
      </Flex>
      <Text fontSize="xs" style={{ fontWeight: '300' }}>
        {time}
      </Text>
    </Flex>
  )
}

export default TextWithDate
