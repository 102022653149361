import { Text, Link, Flex } from '@chakra-ui/react'
import { HiChevronRight } from 'react-icons/hi2'
import TextWithDate from './TextWithDate'

const ExperienceLink = ({ link, text, smallText, time, size, ...props }) => {
  return (
    <Link
      href={link}
      variant="noUnderline"
      display="flex"
      width="100%"
      _hover={{
        '& > div > p > svg': {
          transform: `${size ? 'translateX(6px)' : 'translateX(2px)'}`,
          transition: '0.3s ease',
        },
      }}
      isExternal
      {...props}
    >
      <Flex flexDirection="column" width="100%">
        <Text
          fontSize={size ?? 'xs'}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: '0.5em',
          }}
        >
          {text}
          <HiChevronRight style={{ fontSize: '0.8em' }} />
        </Text>
        <TextWithDate text={smallText} time={time} />
      </Flex>
    </Link>
  )
}

export default ExperienceLink
