import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Stack, Heading, Button, Collapse } from '@chakra-ui/react'
import { ProjectCard } from '../components'
import { useState } from 'react'
import projectData from '../info/projects.json'

const Projects = () => {
  const [show, setShow] = useState(false)
  const DISPLAY_NUM = 2

  const handleToggle = () => setShow(!show)

  return (
    <section id="Projects">
      <Heading size="sm" variant="primary">
        Projects
      </Heading>
      <Stack spacing="2">
        {projectData.slice(0, DISPLAY_NUM).map((project, idx) => (
          <ProjectCard project={project} img={project.image} key={idx} />
        ))}
      </Stack>
      <Collapse in={show} animateOpacity>
        <div style={{ marginTop: '8px' }} />
        <Stack spacing="2">
          {projectData
            .slice(DISPLAY_NUM, projectData.length)
            .map((project, idx) => (
              <ProjectCard project={project} key={idx} />
            ))}
        </Stack>
      </Collapse>
      <Button
        rightIcon={show ? <ChevronUpIcon /> : <ChevronDownIcon />}
        variant="viewMore"
        size="sm"
        onClick={handleToggle}
      >
        View {show ? 'Less' : 'More'}
      </Button>
    </section>
  )
}

export default Projects
