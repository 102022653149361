import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const viewMore = defineStyle({
  marginTop: '0.5em',
  fontWeight: '400',
  float: 'right',
  textDecoration: 'none',
  width: '100%',
  justifyContent: 'flex-end',
  padding: '0px!important',
  _dark: {
    color: 'gray.600',
  },
  _light: {
    color: 'gray.300',
  },
})

export const buttonTheme = defineStyleConfig({
  variants: {
    viewMore: viewMore,
  },
})
