import { AiFillInstagram, AiFillLinkedin, AiFillGithub } from 'react-icons/ai'
import { Link } from '@chakra-ui/react'

const Contact = () => {
  return (
    <div className="contact">
      <Link
        href="https://www.instagram.com/zoraphoto28/"
        variant="icon"
        isExternal
      >
        <AiFillInstagram />
      </Link>
      <Link
        href="https://www.linkedin.com/in/zorazrr/"
        variant="icon"
        isExternal
      >
        <AiFillLinkedin />
      </Link>
      <Link href="https://github.com/zorazrr" variant="icon" isExternal>
        <AiFillGithub />
      </Link>
    </div>
  )
}

export default Contact
