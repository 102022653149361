import { Link, useColorMode, useStyleConfig } from '@chakra-ui/react'

const ColoredLink = ({
  variant = 'boldPrimary',
  color,
  altColor = color,
  ...props
}) => {
  const styles = useStyleConfig('Link', { variant })
  const { colorMode } = useColorMode()

  if (color) {
    styles.color = colorMode === 'light' ? color : altColor
  }

  return <Link variant="boldPrimary" css={styles} {...props} />
}

export default ColoredLink
