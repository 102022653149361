import {
  Box,
  Tag,
  Text,
  Flex,
  Spacer,
  Card,
  CardHeader,
  CardBody,
  TagLabel,
  Image,
} from '@chakra-ui/react'

import ProjectLink from './ProjectLink'

function ProjectCard({ project, img }) {
  return (
    <Box position="relative" _hover={{ '& > img': { opacity: 1 } }}>
      <Image src={img} position="absolute" opacity={0} />
      <Card variant="outline">
        <CardHeader style={{ paddingBottom: '0em', overflow: 'hidden' }}>
          <Flex minWidth="max-content" flexDirection={['column', 'row']}>
            <Text>{project.title}</Text>
            <Spacer />
            <Flex flexDirection="row">
              {project.github && (
                <ProjectLink link={project.github} text="GitHub" />
              )}
              {project.link && (
                <ProjectLink link={project.link} text="Production" />
              )}
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody style={{ paddingTop: '0em' }}>
          <Text fontSize="xs" style={{ fontWeight: '300' }}>
            {project.description}
          </Text>
          <Box>
            {project.tags.map((tag, idx) => (
              <Tag
                style={{
                  marginRight: '0.5em',
                  marginTop: '0.5em',
                  color: 'gray.500',
                }}
                variant="subtle"
                size="sm"
                key={idx}
              >
                <TagLabel style={{ fontWeight: '300' }}>{tag}</TagLabel>
              </Tag>
            ))}
          </Box>
        </CardBody>
      </Card>
    </Box>
  )
}

export default ProjectCard
