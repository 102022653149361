import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Heading, Stack, Collapse, Button } from '@chakra-ui/react'
import { ExperienceLink } from '../components'
import { useState } from 'react'
import expData from '../info/experience.json'

const Experience = () => {
  const [show, setShow] = useState(false)
  const DISPLAY_NUM = 3

  const handleToggle = () => setShow(!show)

  return (
    <section id="Experience">
      <Heading size="sm" variant="primary">
        Work
      </Heading>
      <Stack>
        {expData.slice(0, DISPLAY_NUM).map((exp, idx) => (
          <ExperienceLink
            size="md"
            text={exp.company}
            time={exp.time}
            smallText={exp.position}
            link={exp.link}
            key={idx}
          />
        ))}
      </Stack>
      <Collapse in={show} animateOpacity>
        <div style={{ marginTop: '8px' }} />
        <Stack>
          {expData.slice(DISPLAY_NUM, expData.length).map((exp, idx) => (
            <ExperienceLink
              size="md"
              text={exp.company}
              time={exp.time}
              smallText={exp.position}
              link={exp.link}
              key={idx}
            />
          ))}
        </Stack>
      </Collapse>
      <Button
        rightIcon={show ? <ChevronUpIcon /> : <ChevronDownIcon />}
        variant="viewMore"
        size="sm"
        onClick={handleToggle}
      >
        {' '}
        View {show ? 'Less' : 'More'}
      </Button>
    </section>
  )
}

export default Experience
