import { SunIcon } from '@chakra-ui/icons'
import { useColorMode } from '@chakra-ui/react'

export const Toggle = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <SunIcon
      style={{
        bottom: '2em',
        left: '2em',
        position: 'absolute',
        fontSize: '1.15em',
      }}
      color={colorMode === 'light' ? 'gray.500' : 'gray.300'}
      onClick={() => toggleColorMode()}
    />
  )
}

export default Toggle
