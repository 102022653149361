import { Heading } from '@chakra-ui/react'
import { ColoredLink } from '../components'

const About = () => {
  return (
    <section id="About">
      <Heading size="sm" variant="primary">
        About
      </Heading>
      <dt>
        I studied Computer Science at&nbsp;
        <ColoredLink
          color="#E94A26"
          altColor="#f27e63"
          href="https://cs.illinois.edu/"
          isExternal>
          UIUC
        </ColoredLink> (highest honors).
        <dt>
          Things I like include cats, coffee, and code!
        </dt>
      </dt>
      <br />
      <dt>
        I am currently a SWE Intern at&nbsp;
        <ColoredLink
          color="#EB1600"
          altColor="#f7847c"
          href="https://www.databricks.com/"
          isExternal
        >
          Databricks
        </ColoredLink>
        &nbsp;on the UI Observability team. I have also been involved with&nbsp;
        <ColoredLink
          color="#155DA1"
          altColor="#B4D8FA"
          href="https://uiuc.hack4impact.org/"
        >
          Hack4Impact
        </ColoredLink>
        , where we build software for nonprofits.
      </dt>
    </section>
  )
}

export default About
