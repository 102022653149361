import { ColoredLink } from '../components'

const Recents = () => {
  return (
    <section id="Recents">
      <dt>I am somewhat obsessed with notes taking.</dt>
      <dt>
        Check some of my recent notes on my&nbsp;
        <ColoredLink
          color="#A78BFA"
          altColor="#A78BFA"
          href="https://notes.zorazrr.com/"
          isExternal
        >
          Obsidian Digital Garden
        </ColoredLink>
        .
      </dt>
    </section>
  )
}

export default Recents
