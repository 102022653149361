import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const primary = defineStyle({
  paddingBottom: '1rem',
  fontWeight: 500,
})

const siteTitle = defineStyle({
  fontWeight: 600,
  color: 'pastelBlue.600',
  paddingBottom: '1rem',
  _dark: {
    color: 'pastelBlue.300',
  },
})

export const headingTheme = defineStyleConfig({
  variants: {
    primary: primary,
    siteTitle: siteTitle,
  },
})
